require('./bootstrap');

$.ajaxSetup({
    headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
});

$('.alert:not(.alert-important)').delay(5 * 1000).fadeOut(350);

$('body')
    .on('click', 'form :submit', function() {
        const $input = $(this);
        $input.parents('form').find(':submit').data('clicked', false);
        $input.data('clicked', true)
    })
    .on('submit', 'form', function () {
        const $input = $(':submit', this)
            .prop('disabled', true)
            .filter(function() {
                return $(this).data('clicked') === true
            })
            .first();
        if ($input.attr('name')) {
            $input.parents('form')
                .append(
                    $('<input type="hidden">')
                        .attr('name', $input.attr('name'))
                        .val($input.val())
                )
        }
        const $icon = $input.find('i');
        if ($icon.length > 0) {
            const margin = $icon.hasClass('ml-1') ? 'ml-1' : $icon.hasClass('mr-1') ? 'mr-1' : '';
            $icon.attr('class', `fas fa-circle-notch fa-spin ${margin}`)
        }
    })
    .on('click', '[data-dismiss="popover"]', function() {
        $(this).closest('.popover').popover('hide')
    })
    .popover({
        container: 'body',
        html: true,
        sanitize: false,
        selector: '[data-toggle="popover2"]',
        content() {
            const target = $(this).data('target') || $(this).attr('href');
            return $(target).html()
        }
    });

$('.custom-file-input').on('change', function (e) {
    const name = e.target.files[0].name;
    $(this).next('.custom-file-label').text(name)
});

$('[data-widget="autocomplete"]').select2({
    ajax: {
        delay: 500,
        processResults({ data, meta }) {
            return {
                pagination: meta.current_page < meta.total_page,
                results: data.map(x => ({ ...x, text: x.name })),
            }
        },
    },
    allowClear: true,
    placeholder: 'Select…',
});

$('[data-widget="datepicker"]').datepicker();

$('[data-widget="select2"]').select2();

$(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus()
});

$('#flash-overlay-modal').modal('show');
